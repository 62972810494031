import React, { useState } from "react"
import Img from "gatsby-image"
import { useModalContextToggle} from './modalContext'

const Section = ({ data }) => {

    const toggleModal = useModalContextToggle()

    const [readMore, setReadMore] = useState(false)
    const toggleReadMore = ()=> {
        setReadMore(prev => {
            return !prev;
        })
    }
    return (
        <div className="section">
            <div className="uk-container uk-grid align-center">
                <div className="uk-width-1-1@m uk-text-center">
                    <p className="headline">Here are a few Testimonials</p>
                    <h2 className="uk-text-center">Our Product Is Adored By Our Members</h2>
                    <p className="testimonial-p">Don’t just take our word for it, here’s what our happy users are saying:</p>
                    <div className="uk-grid testimonials">
                        <div className="uk-width-1-3@m uk-margin-small-top uk-scrollspy-inview uk-animation-slide-left-small" uk-scrollspy="cls:uk-animation-slide-left-small">
                            <div className="uk-card uk-card-default">
                                <div className="uk-card-body">
                                    <div className="uk-card-img"><Img fluid={data[0].image.childImageSharp.fluid} /></div>
                                    <p>- {data[0].author}</p>
                                    <blockquote>{data[0].content}</blockquote>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-3@m uk-scrollspy-inview uk-animation-slide-top-small" uk-scrollspy="cls:uk-animation-slide-top-small">
                            <div className="uk-card uk-card-default">
                                <div className="uk-card-body">
                                    <div className="uk-card-img"><Img fluid={data[1].image.childImageSharp.fluid} /></div>
                                    <p>- {data[1].author}</p>
                                    <blockquote className={`${readMore ? '': 'testimonial-quote'}`}>{data[1].content}</blockquote>
                                    <span className="readmore" onClick={toggleReadMore}>{readMore ? 'Show Less': 'Read More'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-3@m uk-margin-small-top uk-scrollspy-inview uk-animation-slide-right-small" uk-scrollspy="cls:uk-animation-slide-right-small">
                            <div className="uk-card uk-card-default">
                                <div className="uk-card-body">
                                    <div className="uk-card-img"><Img fluid={data[2].image.childImageSharp.fluid} /></div>
                                    <p>- {data[2].author}</p>
                                    <blockquote>{data[2].content}</blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Instant Checkmate  provides peace of mind and protection to its happy users, and helps us make the world a safer place!</p>
                    <div onClick={toggleModal} className="uk-button uk-button-primary uk-margin-large-top" style={{margin:'0px auto'}} uk-toggle="#signup-modal">Sign Up as an Affiliate</div>
                </div>
            </div>
        </div>
    )
}

export default Section

